@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body {
  padding: 0;
  margin: 0;
  /*font-family: 'Roboto', sans-serif;*/
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333
}

input {
  /*font-family: 'Roboto', sans-serif;*/
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333
}

.PageLoader_Centered__2EuIo{position:fixed;top:0;left:0;width:100%;height:100vh;background-color:rgba(255,255,255,.8);display:flex;flex-direction:column;justify-content:center;align-items:center;font-size:25px;z-index:2}.PageLoader_Centered__2EuIo span{display:flex;justify-content:center;align-items:center}.PageLoader_Centered__2EuIo span .PageLoader_Circle__1oVSh,.PageLoader_Centered__2EuIo span .PageLoader_Car__1BHwU{position:absolute}.PageLoader_Centered__2EuIo span .PageLoader_Circle__1oVSh{font-size:200px}.PageLoader_Centered__2EuIo span .PageLoader_Car__1BHwU{font-size:100px}.PageLoader_Centered__2EuIo span svg{color:#34b9d7}.PageLoader_Centered__2EuIo .PageLoader_Text__1d7wL{display:block;margin-top:120px;text-align:center}
.Sidebar_Sidebar__g_fOP{display:flex;flex-direction:column;flex-wrap:wrap;background-color:#383838;min-height:100vh;height:100%;padding:0 20px;box-shadow:1px 1px 10px rgba(0,0,0,.3)}.Sidebar_Sidebar__g_fOP .Sidebar_Logo__1ak0g{text-align:center;margin:50px 0}.Sidebar_Sidebar__g_fOP .Sidebar_Menu__xZLc6{display:flex;flex-direction:column;flex-wrap:wrap}.Sidebar_Sidebar__g_fOP .Sidebar_Menu__xZLc6 a{display:flex;flex-direction:row;align-items:center;text-decoration:none;color:#fff;padding:20px 0 20px 20px;border-radius:6px;font-weight:bold;font-size:18px}.Sidebar_Sidebar__g_fOP .Sidebar_Menu__xZLc6 a svg{margin-right:10px;width:30px}.Sidebar_Sidebar__g_fOP .Sidebar_Menu__xZLc6 a.Sidebar_Active__2-g69{background-color:#34b9d7;color:#fff;box-shadow:2px 2px 10px rgba(0,0,0,.4)}@media(max-width: 500px){.Sidebar_Sidebar__g_fOP{height:auto;min-height:inherit;flex-direction:row;justify-content:center;padding:20px 0;margin:0 0}.Sidebar_Sidebar__g_fOP .Sidebar_Logo__1ak0g{display:none}.Sidebar_Sidebar__g_fOP .Sidebar_Menu__xZLc6{flex-direction:row;margin:0}.Sidebar_Sidebar__g_fOP .Sidebar_Menu__xZLc6 a{padding:25px}.Sidebar_Sidebar__g_fOP .Sidebar_Menu__xZLc6 a svg{width:auto;margin:0}.Sidebar_Sidebar__g_fOP .Sidebar_Menu__xZLc6 a .Sidebar_Text__dJgbb{display:none}}
.Header_Header__1psM-{display:flex;flex-direction:row;padding:20px 0;justify-content:flex-end}.Header_Header__1psM- a{color:#f66;margin:0 20px}
.Layout_Layout__3CMIr{display:flex;flex-direction:row}.Layout_Layout__3CMIr .Layout_Menu__3j1wH{flex-basis:15%}.Layout_Layout__3CMIr .Layout_Content__37oK7{flex-basis:85%;display:flex;flex-direction:column}@media(max-width: 500px){.Layout_Layout__3CMIr{flex-direction:column}.Layout_Layout__3CMIr .Layout_Menu__3j1wH{flex-basis:100%}.Layout_Layout__3CMIr .Layout_Content__37oK7{flex-basis:100%}}
