@import '../../shared/shared'

.Layout
  display: flex
  flex-direction: row
  .Menu
    flex-basis: 15%
  .Content
    flex-basis: 85%
    display: flex
    flex-direction: column

@media (max-width: 500px)
  .Layout
    flex-direction: column
    .Menu
      flex-basis: 100%
    .Content
      flex-basis: 100%
