@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
  padding: 0;
  margin: 0;
  /*font-family: 'Roboto', sans-serif;*/
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333
}

input {
  /*font-family: 'Roboto', sans-serif;*/
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333
}
