@import '../../../shared/shared.sass'

.Sidebar
  display: flex
  flex-direction: column
  flex-wrap: wrap
  background-color: $sidebar
  min-height: 100vh
  height: 100%
  padding: 0 20px
  box-shadow: 1px 1px 10px rgba(0,0,0,.3)
  .Logo
    text-align: center
    margin: 50px 0
  .Menu
    display: flex
    flex-direction: column
    flex-wrap: wrap
    a
      display: flex
      flex-direction: row
      align-items: center
      text-decoration: none
      color: #fff
      padding: 20px 0 20px 20px
      border-radius: 6px
      font-weight: bold
      font-size: 18px
      svg
        margin-right: 10px
        width: 30px
    a.Active
      background-color: $primary
      color: #fff
      box-shadow: 2px 2px 10px rgba(0,0,0,.4)

@media (max-width: 500px)
  .Sidebar
    height: auto
    min-height: inherit
    flex-direction: row
    justify-content: center
    padding: 20px 0
    margin: 0 0
    .Logo
      display: none
    .Menu
      flex-direction: row
      margin: 0
      a
        padding: 25px
        svg
          width: auto
          margin: 0
        .Text
          display: none
