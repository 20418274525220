@import '../../../shared/shared.sass'

.Header
  display: flex
  flex-direction: row
  padding: 20px 0
  justify-content: flex-end
  a
    color: $danger
    margin: 0 20px
