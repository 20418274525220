@import '../../../shared/shared.sass'

.Centered
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100vh
  background-color: rgba(255, 255, 255, 0.8)
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  font-size: 25px
  z-index: 2
  span
    display: flex
    justify-content: center
    align-items: center
    .Circle, .Car
      position: absolute
    .Circle
      font-size: 200px
    .Car
      font-size: 100px
    svg
      color: $primary
  .Text
    display: block
    margin-top: 120px
    text-align: center
