//$primary: #dc3545
//$dark_background: #708090
//$light_background: #fafafa
//$black: #212529

$primary: #34B9D7
$primary_dark: #2693AB
$secondary: #546465
$sidebar: #383838
$dark: #03396c
$middle: #6497b1
$light: #b3cde0
$danger_light: #FFC7C7
$danger: #ff6666
$danger_dark: #AE0000
$success_light: #DEFFBA
$success: #66C200
$success_dark: #4A8B02
$warning_light: #FDF1D2
$warning: #F3BB1C
$warning_dark: #BF8E04
$black: #333
